import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

import CategoryHero from 'components/heros/categoryHero';
import GridRow from 'components/grid/gridRow';
import GridItem from 'components/grid/gridItem';
import Heading from 'components/typography/heading';
import BasicTeaser from 'components/teasers/basicTeaser';
import SignUpCta from 'components/ctas/signUpCta';

const Features = ({ data }) => {
  const {
    markdownRemark: { frontmatter: {
      hero,
    } },
    users,
  } = data;

  return (
    <React.Fragment>
      <CategoryHero
        title={hero.title}
        subtitle={hero.subtitle}
        image={hero.image}
      />

      <GridRow
        title={<Heading rank="span" style="h2">Built for coaches at all levels of the game</Heading>}
        gridWrapper="ul"
        gridItems={
          users && users.edges.map((user) => {
            const { node: { frontmatter: {
              path,
              teaser: {
                title, subtitle, icon, image,
              },
            } } } = user;

            return (
              <GridItem key={path} as="li" width={[1, 1 / 2]}>
                <BasicTeaser
                  title={title}
                  subtitle={subtitle}
                  image={image}
                  icon={icon}
                  linkTo={path}
                />
              </GridItem>
            );
          })
        }
      />

      <SignUpCta />
    </React.Fragment>
  );
};

export const query = graphql`
  query($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      ...LayoutFragment
      ...CategoryHeroFragment
    }
    users: allMarkdownRemark(
      filter: {frontmatter: { type: {eq: "user"}}},
      sort: {fields: [frontmatter___teaser___order], order: ASC},
    ) {
      edges {
        node {
          ...BasicTeaserFragment
        }
      }
    }
  }
`;

Features.propTypes = {
  data: PropTypes.object.isRequired,
};

export default Features;
